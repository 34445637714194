<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item label="状态：">
				<el-select v-model="filters.status" placeholder="请选择" style="width: 120px;" clearable>
					<el-option v-for="item in orderStatuss" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="科室：">
				<el-select v-model="filters.departmentId" placeholder="请选择" style="width: 120px;" clearable>
					<el-option v-for="item in depts" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="订单时间：">
				<el-date-picker v-model="filters.timeRange" type="daterange" align="right" unlink-panels
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="医生姓名：">
				<el-input v-model="filters.doctorName" placeholder="医生姓名" clearable></el-input>
			</el-form-item>
			<el-form-item label="患者姓名：">
				<el-input v-model="filters.patientName" placeholder="患者姓名" clearable></el-input>
			</el-form-item>
			<el-form-item label="患者手机号：">
				<el-input v-model="filters.mobilePhone" placeholder="患者手机号" clearable></el-input>
			</el-form-item>
			<el-form-item label="订单编号：">
				<el-input v-model="filters.orderId" placeholder="订单编号" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
				<!-- <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button> -->
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column prop="id" header-align="center" align="center" label="医生头像">
				<template slot-scope="scope">
					<div class="service-cell">
						<img class="doctor-avatar"
							:src="scope.row.avatar ? scope.row.avatar : require('../../images/doctor-def.png')"
							alt="暂无头像" />
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="doctorName" header-align="center" align="center" label="医生姓名"></el-table-column>
			<el-table-column prop="titleName" header-align="center" align="center" label="医生职称"></el-table-column>
			<el-table-column prop="deptName" header-align="center" align="center" label="科室"></el-table-column>
			<el-table-column prop="type" header-align="center" align="center" label="问诊单类型">
				<div slot-scope="scope">{{+scope.row.type === 1 ? '图文问诊' : ''}}</div>
			</el-table-column>
			<el-table-column prop="createdAt" header-align="center" align="center" label="订单时间">
				<template slot-scope="scope">
					{{scope.row.createdAt}}
				</template>
			</el-table-column>
			<el-table-column prop="orderId" header-align="center" align="center" label="订单编号"></el-table-column>
			<el-table-column prop="patientId" header-align="center" align="center" label="患者信息">
				<template slot-scope="scope">
					<div>
						<div>{{scope.row.patientName}}</div>
						<div>{{scope.row.showFullPhone ? scope.row.mobilePhone : scope.row.mobilePhoneHide}}</div>
						<div class="click-to-show" @click="clickToShow(scope.row)">
							<a>{{scope.row.showFullPhone ? '点击隐藏' : '点击查看'}}</a>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="status" header-align="center" align="center" label="当前状态">
				<template slot-scope="scope">
					{{scope.row.status === 1 ? '待支付' :
						scope.row.status === 2 ? '问诊中' :
						scope.row.status === 3 ? '已完成' :
						scope.row.status === 4 ? '已取消' : '已退款'
					}}
				</template>
			</el-table-column>
			<el-table-column prop="totalFee" header-align="center" align="center" label="实付金额（元）"></el-table-column>
			<el-table-column header-align="center" align="center" label="分成">
				<template slot-scope="scope">
					<p>
						操作人员：{{JSON.parse(scope.row.divideIntoRemark || '{}').operator || ''}}
					</p>
					<p>
						分成百分比：{{JSON.parse(scope.row.divideIntoRemark || '{}').percentage ? JSON.parse(scope.row.divideIntoRemark || '{}').percentage + '%' : ''}}
					</p>
					<p>
						操作时间：{{JSON.parse(scope.row.divideIntoRemark || '{}').optTime ? new Date(JSON.parse(scope.row.divideIntoRemark || '{}').optTime).toLocaleDateString() : ''}}
					</p>
				</template>
			</el-table-column>
			<el-table-column header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-popconfirm
						v-if="scope.row.status === 1"
						title="确认取消这条问诊订单？"
						@confirm="cancelHandle(scope.row.id)">
						<el-button style="margin-left: 10px" slot="reference" type="text" size="small">取消</el-button>
					</el-popconfirm>
					<el-button v-if="[2, 3].indexOf(scope.row.status) > -1 && scope.row.totalFee > 0" type="text" size="small"
						@click="toRefund(scope.row)">退款</el-button>
					<!-- 处于完成状态的问诊单才可以分成 -->
					<el-button v-if="scope.row.divideIntoable && scope.row.status === 3" type="text" size="small" @click="toDivideInto(scope.row)">
						分成
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		<!-- 退款 对话框 -->
		<el-dialog title="退款" :visible.sync="refundDialogVisible">
			<el-form :model="refundForm" :rules="refundRules" ref="ruleForm">
				<el-form-item label="退款金额" prop="refundFee">
					<div>￥{{refundForm.refundFee}}</div>
					<!-- <el-input-number v-model="refundForm.refundFee" :precision="2" :step="0.1" :min="0" :max="refundForm.maxFee" disabled></el-input-number> -->
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="refundForm.remark" autocomplete="on"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="refundDialogVisible = false;">取 消</el-button>
				<el-button type="primary" @click="refund">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 分成 对话框 -->
		<el-dialog title="分成" :visible.sync="divideIntoDialogVisible">
			<el-form :model="divideIntoForm">
				<el-form-item label="分成比例">
					<el-input-number v-model="divideIntoForm.percentage" :precision="2" :step="1" :min="0" :max="100"></el-input-number>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="divideIntoForm.divideIntoRemark"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="divideIntoDialogVisible = false;">取 消</el-button>
				<el-button type="primary" @click="divideInto">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
// import { dateFormat } from '@/utils';
export default {
	data () {
		return {
			filters: {
				doctorName: '',
				status: '',
				departmentId: '',
				timeRange: '',
				patientName: '',
				mobilePhone: '',
				orderId: ''
			},
			orderStatuss: [{
				label: '全部',
				value: ''
			},
			{
				label: '待支付',
				value: 1
			},
			{
				label: '问诊中',
				value: 2
			},
			{
				label: '已完成',
				value: 3
			},
			{
				label: '已取消',
				value: 4
			}
			],
			depts: [],
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			refundDialogVisible: false,
			divideIntoDialogVisible: false,
			divideIntoForm: {
				percentage: 100,
				divideIntoRemark: '',
				operator: ''
			},
			refundForm: {
				id: '',
				refundFee: 0,
				maxFee: 0,
				remark: '',
				type: 0 // 写死的问诊单类型
			},
			refundRules: {
				refundFee: [
					{ required: true, message: '请输入退款金额', trigger: 'blur' }
				],
				remark: [
					{ required: true, message: '请输入退款备注', trigger: 'blur' }
				]
			}
		};
	},
	created () {
		this.search();
		this.getDepts();
		this.divideIntoForm.operator = JSON.parse(window.localStorage.getItem('smt_ui')).nickname;
	},
	methods: {
		getDepts () {
			this.$root.request('deptList', {
				page: 1,
				limit: 1000
			}).then((data) => {
				if (data) {
					this.depts = data.rows.map(r => {
						return {
							label: r.name,
							value: r.id
						};
					});
					this.depts.unshift({
						label: '全部',
						value: ''
					});
				}
			});
		},
		search () {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			this.getData();
		},
		clickToShow (item) {
			item.showFullPhone = !item.showFullPhone;
		},
		getData () {
			if (!this.filters.timeRange) this.filters.timeRange = ['', ''];
			this.$root.request('consultationList', {
				// keywords: this.keywords,
				...this.filters,
				startTime: this.filters.timeRange[0],
				endTime: this.filters.timeRange[1],
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows.map(r => {
						r.showFullPhone = false;
						r.mobilePhoneHide = r.mobilePhone.substr(0, 3) + '****' + r.mobilePhone.substr(
							7);
						return r;
					});
				}
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		},
		toRefund (item) {
			this.refundDialogVisible = true;
			this.refundForm.id = item.id;
			this.refundForm.maxFee = item.totalFee;
			this.refundForm.refundFee = item.totalFee;
		},
		refund () {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.$root.request('refundRecordAdd', this.refundForm).then((data) => {
						if (data && data.ok === 1) {
							this.$root.elMsg('操作成功');
							this.refundDialogVisible = false;
							this.refundForm = {
								id: '',
								refundFee: 0,
								maxFee: 0,
								remark: '',
								type: 0 // 写死的药品单
							};
							this.getData();
						}
					});
				} else {
					return false;
				}
			});
		},
		toDivideInto (item) {
			this.divideIntoDialogVisible = true;
			this.divideIntoForm.consultationId = item.id;
			this.divideIntoForm.orderId = item.orderId;
			this.divideIntoForm.doctorUserId = item.doctorUserId;
			this.divideIntoForm.totalFee = item.totalFee;
		},
		divideInto () {
			this.$root.request('divideIntoApi', {
				...this.divideIntoForm
			}).then((data) => {
				if (data && data.ok === 1) {
					this.$root.elMsg('操作成功');
					this.refundDialogVisible = false;
					this.divideIntoForm = {
						percentage: 100,
						divideIntoRemark: ''
					};
					this.getData();
					this.divideIntoDialogVisible = false;
				}
			});
		}
	}
};
</script>
<style lang="less" scoped>
	.service-cell {
		display: flex;
		align-items: center;

		div {
			text-align: left;
			margin-left: 10px;
		}

		.doctor-avatar {
			width: 80px;
			height: 80px;
		}
	}

	.click-to-show {
		cursor: pointer;
		color: deepskyblue;
	}
</style>