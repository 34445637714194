var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: { inline: true },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.search($event)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "状态：" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.filters.status,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "status", $$v)
                    },
                    expression: "filters.status"
                  }
                },
                _vm._l(_vm.orderStatuss, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "科室：" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.filters.departmentId,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "departmentId", $$v)
                    },
                    expression: "filters.departmentId"
                  }
                },
                _vm._l(_vm.depts, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单时间：" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "picker-options": _vm.pickerOptions
                },
                model: {
                  value: _vm.filters.timeRange,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "timeRange", $$v)
                  },
                  expression: "filters.timeRange"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "医生姓名：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "医生姓名", clearable: "" },
                model: {
                  value: _vm.filters.doctorName,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "doctorName", $$v)
                  },
                  expression: "filters.doctorName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "患者姓名：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "患者姓名", clearable: "" },
                model: {
                  value: _vm.filters.patientName,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "patientName", $$v)
                  },
                  expression: "filters.patientName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "患者手机号：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "患者手机号", clearable: "" },
                model: {
                  value: _vm.filters.mobilePhone,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "mobilePhone", $$v)
                  },
                  expression: "filters.mobilePhone"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单编号：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "订单编号", clearable: "" },
                model: {
                  value: _vm.filters.orderId,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "orderId", $$v)
                  },
                  expression: "filters.orderId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "id",
              "header-align": "center",
              align: "center",
              label: "医生头像"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "service-cell" }, [
                      _c("img", {
                        staticClass: "doctor-avatar",
                        attrs: {
                          src: scope.row.avatar
                            ? scope.row.avatar
                            : require("../../images/doctor-def.png"),
                          alt: "暂无头像"
                        }
                      })
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "doctorName",
              "header-align": "center",
              align: "center",
              label: "医生姓名"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "titleName",
              "header-align": "center",
              align: "center",
              label: "医生职称"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deptName",
              "header-align": "center",
              align: "center",
              label: "科室"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              "header-align": "center",
              align: "center",
              label: "问诊单类型"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _c("div", {}, [
                    _vm._v(_vm._s(+scope.row.type === 1 ? "图文问诊" : ""))
                  ])
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createdAt",
              "header-align": "center",
              align: "center",
              label: "订单时间"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(scope.row.createdAt) + "\n\t\t\t"
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderId",
              "header-align": "center",
              align: "center",
              label: "订单编号"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "patientId",
              "header-align": "center",
              align: "center",
              label: "患者信息"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _c("div", [_vm._v(_vm._s(scope.row.patientName))]),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            scope.row.showFullPhone
                              ? scope.row.mobilePhone
                              : scope.row.mobilePhoneHide
                          )
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "click-to-show",
                          on: {
                            click: function($event) {
                              return _vm.clickToShow(scope.row)
                            }
                          }
                        },
                        [
                          _c("a", [
                            _vm._v(
                              _vm._s(
                                scope.row.showFullPhone
                                  ? "点击隐藏"
                                  : "点击查看"
                              )
                            )
                          ])
                        ]
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              "header-align": "center",
              align: "center",
              label: "当前状态"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          scope.row.status === 1
                            ? "待支付"
                            : scope.row.status === 2
                            ? "问诊中"
                            : scope.row.status === 3
                            ? "已完成"
                            : scope.row.status === 4
                            ? "已取消"
                            : "已退款"
                        ) +
                        "\n\t\t\t"
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "totalFee",
              "header-align": "center",
              align: "center",
              label: "实付金额（元）"
            }
          }),
          _c("el-table-column", {
            attrs: { "header-align": "center", align: "center", label: "分成" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        "\n\t\t\t\t\t操作人员：" +
                          _vm._s(
                            JSON.parse(scope.row.divideIntoRemark || "{}")
                              .operator || ""
                          ) +
                          "\n\t\t\t\t"
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n\t\t\t\t\t分成百分比：" +
                          _vm._s(
                            JSON.parse(scope.row.divideIntoRemark || "{}")
                              .percentage
                              ? JSON.parse(scope.row.divideIntoRemark || "{}")
                                  .percentage + "%"
                              : ""
                          ) +
                          "\n\t\t\t\t"
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n\t\t\t\t\t操作时间：" +
                          _vm._s(
                            JSON.parse(scope.row.divideIntoRemark || "{}")
                              .optTime
                              ? new Date(
                                  JSON.parse(
                                    scope.row.divideIntoRemark || "{}"
                                  ).optTime
                                ).toLocaleDateString()
                              : ""
                          ) +
                          "\n\t\t\t\t"
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              width: "150",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 1
                      ? _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确认取消这条问诊订单？" },
                            on: {
                              confirm: function($event) {
                                return _vm.cancelHandle(scope.row.id)
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "small"
                                },
                                slot: "reference"
                              },
                              [_vm._v("取消")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    [2, 3].indexOf(scope.row.status) > -1 &&
                    scope.row.totalFee > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.toRefund(scope.row)
                              }
                            }
                          },
                          [_vm._v("退款")]
                        )
                      : _vm._e(),
                    scope.row.divideIntoable && scope.row.status === 3
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.toDivideInto(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n\t\t\t\t\t分成\n\t\t\t\t")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { float: "right", "margin-top": "20px" } },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "退款", visible: _vm.refundDialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.refundDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: { model: _vm.refundForm, rules: _vm.refundRules }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "退款金额", prop: "refundFee" } },
                [_c("div", [_vm._v("￥" + _vm._s(_vm.refundForm.refundFee))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "on" },
                    model: {
                      value: _vm.refundForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.refundForm, "remark", $$v)
                      },
                      expression: "refundForm.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.refundDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.refund } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "分成", visible: _vm.divideIntoDialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.divideIntoDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.divideIntoForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分成比例" } },
                [
                  _c("el-input-number", {
                    attrs: { precision: 2, step: 1, min: 0, max: 100 },
                    model: {
                      value: _vm.divideIntoForm.percentage,
                      callback: function($$v) {
                        _vm.$set(_vm.divideIntoForm, "percentage", $$v)
                      },
                      expression: "divideIntoForm.percentage"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.divideIntoForm.divideIntoRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.divideIntoForm, "divideIntoRemark", $$v)
                      },
                      expression: "divideIntoForm.divideIntoRemark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.divideIntoDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.divideInto } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }