import { render, staticRenderFns } from "./Consultation.vue?vue&type=template&id=6278ee64&scoped=true&"
import script from "./Consultation.vue?vue&type=script&lang=js&"
export * from "./Consultation.vue?vue&type=script&lang=js&"
import style0 from "./Consultation.vue?vue&type=style&index=0&id=6278ee64&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6278ee64",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev/fe/ssl-management/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6278ee64')) {
      api.createRecord('6278ee64', component.options)
    } else {
      api.reload('6278ee64', component.options)
    }
    module.hot.accept("./Consultation.vue?vue&type=template&id=6278ee64&scoped=true&", function () {
      api.rerender('6278ee64', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/Consultation.vue"
export default component.exports